import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components';
import videoSrc from '../assets/videos/hero.mp4';

const Wrapper = styled.div`
    position: relative;
    width: 100vw;
    min-height: ${props => props.isBig ? '100vh' : '75vh'};
    height: ${props => props.isVideo ? '100vh' : 'none'};
    overflow: hidden;

    @media screen and (max-width: 991px) {
        min-height: 400px;

        .hero {
            margin-top: 70px;
            width: 100%;
            height: 75vh;
            object-fit: cover;
        }
    }

    .video-wrapper {
        height: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        width: 100%;
        z-index: 0;

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        background: rgba(17, 17, 17, .4);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:before {
        background: url(${props => props.bgImage});
        
        ${props => !props.isVideo && `
            @media screen and (min-width: 991px) {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 75vh;
                object-fit: cover;
                background-attachment: fixed;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
            }
        `}
    }
    
    .chevron-down {
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translate(-50%);        
        cursor: pointer;
    }
`

const Hero = ({ isBig, img, title, subtitle, children, isVideo }) => {

    const videoRef = useRef();

    const isSafari = () => {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
    }


    useEffect(() => {

        if (isSafari() && videoRef.current) {
            // obtain reference to the video element
            const player = videoRef.current.children[0];

            // if the reference to video player has been obtained
            if (player) {
                // set the video attributes using javascript as per the
                // webkit Policy
                player.controls = false;
                player.playsinline = true;
                player.muted = true;
                player.loop = true;
                player.setAttribute("muted", ""); // leave no stones unturned :)
                player.autoplay = true;

                // Let's wait for an event loop tick and be async.
                setTimeout(() => {
                    // player.play() might return a promise but it's not guaranteed crossbrowser.

                    if (player.play) {
                        const promise = player.play();
                        // let's play safe to ensure that if we do have a promise
                        if (promise.then) {
                            promise
                                .then(() => { })
                                .catch(() => { });
                        }
                    }
                }, 0);
            }
        }

    }, [videoRef]);


    return (
        <>
            <header>
                <Wrapper bgImage={img} isBig={isBig} isVideo={isVideo} className={classNames("header-wrapper")}>
                    {isBig && (
                        <>
                            <div className="video-wrapper">
                                <video
                                    ref={videoRef}
                                    controls={false}
                                    muted={true}
                                    autoPlay={true}
                                    playsInline={true}
                                    loop={true}
                                    width="100%"
                                >
                                    <source src={videoSrc} type="video/mp4"></source>
                                </video>
                            </div>
                        </>
                    )}
                    <img className="img-fluid hero d-lg-none" src={img} alt={title}></img>
                    <div className="content">
                        <div>
                            <h1>{title}</h1>
                            <hr className="my-4 white" />
                            {subtitle && (<p className="text-heading">{subtitle}</p>)}

                            <div>
                                {children}
                            </div>

                            {isBig && (
                                <a href="#chevron" className="chevron-down" id="chevron">
                                    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </a>
                            )}
                        </div>
                    </div>
                </Wrapper>
            </header>
        </>
    )
}

Hero.defaultProps = {
    isBig: false,
    img: "",
    title: "",
    subtitle: "",
}

Hero.propTypes = {
    isBig: PropTypes.bool,
    img: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
}

export default Hero;