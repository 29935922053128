import React from 'react';
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import withTrans from '../i18n/withTranslation';


const PricesPage = ({ data, t }) => {

  const getContent = (translation) => {
    return { __html: `${t(translation)}` }
  }

  return (
    <>
      <Layout
        title={t('site.pages.PricesPage.meta.title')}
        description={t('site.pages.PricesPage.meta.description')}
        img={data.header.childImageSharp.fluid.src}
      >
        <Hero
          isDark={true}
          title={t('site.pages.PricesPage.title')}
          alt={t('site.pages.PricesPage.headerAlt')}
          img={data.header.childImageSharp.fluid.src}
        />

        <div className="container my-5">
          <table class="table table-striped">
            <thead class="thead-dark">
              <tr>
                <th scope="col">{t('site.pages.PricesPage.table.titleService')}</th>
                <th scope="col">{t('site.pages.PricesPage.table.titlePrice')}</th>
              </tr>
            </thead>
            <tbody>
              {t('site.pages.PricesPage.table.list').map(({ service, price }, index) => {
                return <tr>
                  <th scope="row">{service}</th>
                  <td>{price}</td>
                </tr>;
              })}
            </tbody>
          </table>



        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query PricesPage {
    header: file(relativePath: {eq: "hero-prices.jpg"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          src
        }
      }
    }
  }
`;

export default withTrans(PricesPage);